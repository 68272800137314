import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'underscore';
import GraphView from './GraphView';
import utils from './../utils/graphUtils';
import config from './../config';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();

		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class WordGraphView extends Component {
	constructor(props) {
		super(props);

		this.relTypes = [
			{
				label: 'Sjá',
				type: 'SJA'
			},
			{
				label: 'Skylt',
				type: 'SK'
			},
			{
				label: 'Samanber',
				type: 'SBR'
			},
			{
				label: 'Athuga',
				type: 'ATH'
			}
		]

		this.state = {
			graphData: {},
			flettur: true
		};
	}

	componentDidMount() {
		if (this.props.personId) {
			this.getData(this.props.personId);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.personId && this.props.personId != prevProps.personId) {
			this.getData(this.props.personId)
		}
	}

	getData(personId) {
		fetch(config.apiRoot+'travel/network/?limit=1000&person_id='+personId)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json.results)
				});
			});
	}


	render() {
		return <div className="relative">
			<GraphView straightLines={true}
				distance={200}
				graphData={this.state.graphData}
				disableZoom={false}
				highlightId={[this.props.personId]}
				mainId={this.props.personId}
				onNodeClick={function(d) {
					console.log(d)
					if (d.id) {
						let url = d.traveller ? '/travelling/women/'+d.id : '/travelling/persons/'+d.id;
						if (this.props.isEmbed) {
							window.open(url, '_parent')
						}
						else {
							this.props.navigate(url);
						}
					}
					//this.props.history.push(helpers.routerUrls[d.label.toLowerCase()]+d.properties.ismus_id.low+(this.props.tabname ? '/'+this.props.tabname : ''));
				}.bind(this)}
				enableFullView={true}>
			</GraphView>

		</div>
	}
}


export default withRouter(WordGraphView);
