import { useEffect, useRef, useState } from "react";
import { Routes, Route, Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import './css/app.css';

import logoRannis from './img/rannis.png';
import logoArnastofnun from './img/arnastofnun.png';
import logoLhi from './img/lhi.png';

import kvennasporLogo from './img/kvennaspor.png';

import MapView from './components/MapView';
import PlaceView from './components/PlaceView';

import TravelList from './components/TravelList';
import TravelView from './components/TravelView';
import BibliographyList from './components/BibliographyList';
import BibliographyView from './components/BibliographyView';

import PersonList from './components/PersonList';
import WomenList from './components/WomenList';
import PersonView from './components/PersonView';

import NewsList from './components/NewsList';

import { Container, PageHeading } from "./components/Components";

function App() {
	const [menuOpen, setMenuOpen] = useState(false);
	const location = useLocation();
	const appMenu = useRef();

	useEffect(() => {
		setMenuOpen(false);
	}, [location]);

	const clickOutside = (event) => {
		if (appMenu.current && !appMenu.current.contains(event.target)) {
			setMenuOpen(false);
		}
	}

	useEffect(() => {
		window.document.addEventListener("mousedown", (clickOutside));

		return () => {
			window.document.removeEventListener("mousedown", (clickOutside));
		}
	});

	return <div className="app relative">

		<button className="lg:hidden fixed top-14 right-10 justify-self-end block space-y-2 transition-transform hover:scale-110 z-50 pr-4"  onClick={() => setMenuOpen(!menuOpen)}>
			<div className="transition-all w-8 h-0.5 bg-black"></div>
			<div className="transition-all w-8 h-0.5 bg-black"></div>
			<div className="transition-all w-8 h-0.5 bg-black"></div>
		</button>

		<div ref={appMenu} className={'header fixed top-0 min-h-screen font-lora lg:text-right pt-10 px-10 w-[390px] z-10 transition-all duration-[600ms]  lg:translate-x-0 right-0 lg:right-auto bg-white bg-no-repeat bg-top-right'+(!menuOpen ? ' translate-x-[400px]' : '')} style={{
		backgroundImage: 'url(/img/background-header-opacity.png)'
	}} >

			<Link className="no-underline transition-opacity duration-500 flex lg:justify-end mt-6" to="/">
				<img className="max-w-[100px]" src={kvennasporLogo} />
			</Link>
	
			<div className="pt-10">
				{
					[
						['/about', 'About'],
						['/news', 'News and Events'],
						//['/places', 'Places'],
						['/outputs', 'Outputs'],
					].map((item, index) => <NavLink to={item[0]}
						className="block no-underline text-lg"
						className={({ isActive, isPending }) =>
							isActive ? 'block no-underline text-lg font-bold' : 'block no-underline text-lg'
					  }
					>{item[1]}</NavLink>)
				}
			</div>

			<div className="absolute bottom-5 left-0 right-10 gap-4 flex flex-col item-right">
				<a href="http://rannis.is" className="text-right w-full"><img className="inline-block max-w-[50px]" src={logoRannis} /></a>
				<a href="http://arnastofnun.is" className="text-right w-full"><img className="inline-block max-w-[110px]" src={logoArnastofnun} /></a>
				<a href="http://lhi.is" className="text-right w-full"><img className="inline-block max-w-[130px]" src={logoLhi} /></a>
				<div className="pt-5 text-xs">This website and its contents is copyright the Kvennaspor project 2024. Please send enquries or comments to emily.lethbridge[at]arnastofnun.is</div>
			</div>
		</div>

		<div className="h-full pr-8 pt-4 pb-10 pl-8 lg:pl-0">
			<Routes>

				<Route path="/" element={<>
					<Container>
						<PageHeading large={true} subHeading={
							<>Afhjúpun og ljómun kvenna<br/>í sagnalandslagi Íslands</>
						}>Kvennaspor</PageHeading>

						<div className="pt-5 flex gap-5">
							{
								[
									['/sagas', 'Saga Women'],
									['/travelling', 'Travelling Women'],
									['/practice-as-research', 'Practice as Research'],
								].map((item, index) => <NavLink key={index} to={item[0]}
									className="block no-underline text-lg"
									className={({ isActive, isPending }) =>
										'block font-medium font-lora text-lg no-underline text-center p-5 rounded-md shadow-md bg-white/90 bg-center bg-contain bg-no-repeat transition-all duration-500 group-hover:scale-105'
								}
								>{item[1]}</NavLink>)
							}
						</div>
					</Container>
				</>} />


				<Route path="/travelling" element={<>
					<Container>
						<PageHeading>Travelling Women</PageHeading>

						<div className="pt-5 flex gap-5">
							{
								[
									['/travelling/women', 'The Women'],
									['/travelling/writing', 'The Writing'],
									['/travelling/persons', 'The Networks'],
								].map((item, index) => <NavLink key={index} to={item[0]}
									className="block no-underline text-lg"
									className={({ isActive, isPending }) =>
										'block font-medium font-lora text-lg no-underline text-center p-5 rounded-md shadow-md bg-white/90 bg-center bg-contain bg-no-repeat transition-all duration-500 group-hover:scale-105'
								}
								>{item[1]}</NavLink>)
							}
						</div>
					</Container>
				</>} />

				<Route path="travelling/places" element={<>
					<Container>
						<PageHeading>Places</PageHeading>
						<MapView />
					</Container>
				</>} />
				<Route path="travelling/places/:placeId" element={<>
	
					<Container>
						<PageHeading>Places</PageHeading>
						<MapView />
					</Container>
						
					<PlaceView />

				</>} />

				<Route path="travelling/travels" element={<>
					<Container>
						<PageHeading>Travels</PageHeading>
					</Container>

					<TravelList />
				</>} />

				<Route path="travelling/writing/:writingId" element={<BibliographyView />} />

				<Route path="travelling/writing" element={<>
					<Container>
						<PageHeading>The Writing</PageHeading>
					</Container>

					<BibliographyList />
				</>} />

				<Route path="travelling/travels/:travelId" element={<TravelView />} />

				<Route path="travelling/women" element={<>
					<Container>
						<PageHeading>The Women</PageHeading>
					</Container>

					<WomenList />
				</>} />

				<Route path="travelling/women/:personId" element={<PersonView />} />

				<Route path="travelling/persons" element={<>
					<Container>
						<PageHeading>Persons</PageHeading>
					</Container>

					<PersonList />
				</>} />

				<Route path="travelling/persons/:personId" element={<PersonView />} />

				<Route path="/news/:newsId?" element={<>
					<Container>
						<PageHeading>News and Events</PageHeading>
						<NewsList />
					</Container>
				</>} />

				<Route path="/*" element={<>
					<Container>
						<PageHeading></PageHeading>
					</Container>
				</>} />

			</Routes>
		</div>

	</div>
}

export default App;
