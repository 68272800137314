import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Container, PageHeading } from "./Components";

import config from "../config";

function TravelList(props) {
	const [travelsData, setTravelsData] = useState(null);

	let { placeId } = useParams()

	const apiRoot = config.apiRoot+'travel/travels'

	useEffect(() => {
		if (!travelsData) {
			fetch(apiRoot)
				.then(res => res.json())
				.then(json => setTravelsData(json.results));
		}
	});

	return <Container>
		{
			travelsData && <table>
				<thead>
					<th>Author</th>
					<th>Year</th>
					<th>Publication</th>
				</thead>
				<tbody>
					{
						travelsData.map((item, index) => <tr key={index}>
							<td><Link to={'/travelling/travels/'+item.id}>{item.person.name}</Link></td>
							<td>{item.travel_dates && item.travel_dates.length > 0 && item.travel_dates[0]}</td>
							<td>{item.travel_publication && item.travel_publication.title}</td>
						</tr>)
					}
				</tbody>
			</table>
		}

	</Container>;
}

export default TravelList;