import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import _ from 'underscore';

import { Container, PageHeading, Heading, LinkIcon } from "./Components";

import config from "../config";

function BibliographyView(props) {
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	const map = useRef();
	const markerRefs = useRef({});

	let { writingId } = useParams();

	const apiRoot = config.apiRoot+'travel/';

	useEffect(() => {
		fetch(apiRoot+'bibliographies/'+writingId)
			.then(res => res.json())
			.then(json => {
				setData(json);
			});
	}, [writingId]);

	return <div>
		{
			data && <>
				<Container>
					<PageHeading extraContent={<a href={data.source_url} className="button p-3">View <LinkIcon /></a>}>{data.title}</PageHeading>

					<p>{data.publisher}, {data.publication_date}{data.extra_publishing_info ? ', '+data.extra_publishing_info : ''}</p>

					{
						data.author && <p className="my-8">Author: <Link className="button p-3" to={'/travelling/women/'+data.author.id}>{data.author.name}</Link></p>
					}


					<div className="flex flex-row-reverse gap-6">
						{
							data.author && <Link to={'/travelling/women/'+data.author.id} 
							className={'hidden group no-underline basis-1 '+(data.author.image ? 'text-center' : '')}
						>
							{
								data.author.image && <div className="rounded-md shadow-md bg-white/50 bg-center bg-contain bg-no-repeat transition-all duration-500 group-hover:scale-105 w-[200px] h-[240px]" style={data.author.image ? {
									backgroundImage: 'url('+data.author.image.replace('130.208.108.18:8000', 'ginnungagap.arnastofnun.is')+')'
								} : null} />
							}
		
							<p className="mt-2 -mb-2">Author:</p>
							<h4 className="group-hover:underline decoration-slate-400 mb-2">
								{data.author.name}
							</h4>
							{
								/*
							<div className="text-gray-500 text-sm">Travelled to Iceland in {
									_.uniq(_.compact(_.flatten(travelData.person.travel_author.map(travel => travel.travel_dates.map(date => date))))).join(', ')
								}
							</div>
								*/
							}
						</Link>
					}

						<div className="w-full">
							{
								data.travels && data.travels.length > 0 && <>
									<Heading>Associated travels</Heading>
									<table>
										<thead>
											<th>Author</th>
											<th>Year</th>
										</thead>
										<tbody>
											{
												data.travels.map((item, index) => <tr key={index}>
													<td><Link to={'/travelling/travels/'+item.id}>{item.person.name}</Link></td>
													<td>{item.travel_dates && item.travel_dates.length > 0 && item.travel_dates[0]}</td>
												</tr>)
											}
										</tbody>
									</table>
								</>
							}
						</div>

					</div>

					{
						data.person_travels && data.person_travels.length > 0 && <>
							<Heading>Appears in travel narratives</Heading>
							<table>
								<thead>
									<th>Ferðabók</th>
									<th>Reference mode</th>
									<th>Hlutverk</th>
								</thead>
								<tbody>
									{
										data.person_travels.map((item, index) => <tr key={index}>
											<td><Link to={'/travelling/travels/'+item.travel.id}>{item.travel.title}</Link></td>
											<td>{item.reference_mode}</td>
											<td>{item.person_role}</td>
										</tr>)
									}
								</tbody>
							</table>
						</>
					}

				</Container>

			</>
		}
	</div>
}

export default BibliographyView;