import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, CircleMarker } from 'react-leaflet';
import L, { circleMarker } from 'leaflet';
import _ from 'underscore';

import 'leaflet/dist/leaflet.css'

import config from "../config";

function MapView() {
	const [mapData, setMapData] = useState([]);
	const [markerType, setMarkerType] = useState('markers');
	const [eventCount, setEventCount] = useState(null);
	const [travelCount, setTravelCount] = useState(null);

	const navigate = useNavigate();

	const markerRefs = useRef({});
	const map = useRef();

	let { placeId } = useParams()

	const apiRoot = config.apiRoot+'travel/places/'

	const iconResize = 0.6;

	const defaultIcon = new L.icon({
		iconAnchor: [12*iconResize, 41*iconResize],
		iconRetinaUrl: '/kvennaspor-frontend/img/marker-icon-2x.png',
		iconSize: [25*iconResize, 41*iconResize],
		iconUrl: '/kvennaspor-frontend/img/marker-icon.png',
		popupAnchor: [1*iconResize, -34*iconResize],
		shadowSize: [41*iconResize, 41*iconResize],
		shadowUrl: '/kvennaspor-frontend/img/marker-shadow.png',
		tooltipAnchor: [16*iconResize, -28*iconResize]
	});

	const circleMarkerOptions = {
		color: '#000000',
		fillColor: '#ffffff',
		fillOpacity: 0.7,
		weight: 1
	};

	useEffect(() => {
		fetch(apiRoot+'?limit=2000')
			.then(res => res.json())
			.then(json => {
				setMapData(json.results);

				let minCircleRadius = 6;
				let maxCircleRadius = 25;
		
				let minEventCount = _.min(_.pluck(json.results, 'event_count'));
				let maxEventCount = _.max(_.pluck(json.results, 'event_count'));

				let minTravelCount = _.min(_.pluck(json.results, 'travel_count'));
				let maxTravelCount = _.max(_.pluck(json.results, 'travel_count'));

				setEventCount({
					minCount: minEventCount,
					maxCount: maxEventCount,
					range: (value) => (value - minEventCount) * (maxCircleRadius - minCircleRadius) / (maxEventCount - minEventCount) + minCircleRadius
				});
	
				setTravelCount({
					minCount: minTravelCount,
					maxCount: maxTravelCount,
					range: (value) => (value - minTravelCount) * (maxCircleRadius - minCircleRadius) / (maxTravelCount - minTravelCount) + minCircleRadius
				});
	
				if (map.current) {
					map.current.fitBounds(json.results.map((item) => [item.lat, item.lng]), {
						padding: [40, 40]
					});
				}
			})
	}, []);

	useEffect(() => {
		if (mapData.length > 0) {
			let marker = markerRefs.current[placeId];

			if (marker) {
				marker.openPopup();

				map.current.setView(marker._latlng, map.current.getZoom() < 8 ? 8 : map.current.getZoom());
			}
		}
	}, [placeId, mapData]);

	return (
		<div>
			<div className="flex mb-4">
				<a className={'button mr-2'+(markerType == 'markers' ? ' selected' : '')} onClick={() => setMarkerType('markers')}>Punktar</a>
				<a className={'button mr-2'+(markerType == 'events' ? ' selected' : '')} onClick={() => setMarkerType('events')}>Áfangastaðir</a>
				<a className={'button mr-2'+(markerType == 'travels' ? ' selected' : '')} onClick={() => setMarkerType('travels')}>Ferðabækur</a>
			</div>

			<MapContainer ref={map} center={[64.963051, -19.020836]} minZoom={4} maxZoom={12} zoom={6} scrollWheelZoom={true}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://nafnid.is/gis/island-tiles/{z}/{x}/{y}.png"
				/>
				<TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
					attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
				/>
				<FeatureGroup>
					{
						mapData.map((item, index) => markerType == 'markers' ? <Marker key={index}
							icon={defaultIcon}
							title={item.name}
							position={[item.lat, item.lng]}
							eventHandlers={{
								click: (event) => navigate('/places/'+item.id)
							}}
							ref={(m) => {
								markerRefs.current[item.id] = m;
							}}
						>
							<Popup><div className="text-sm font-bold">{item.name}</div></Popup>
						</Marker> : <CircleMarker key={index}
							title={item.name}
							center={[item.lat, item.lng]}
							pathOptions={circleMarkerOptions}
							radius={eventCount || travelCount ? (markerType == 'events' ? eventCount : travelCount).range(item[markerType == 'events' ? 'event_count' : 'travel_count']) : 6}
							eventHandlers={{
								click: (event) => navigate('/places/'+item.id)
							}}
							ref={(m) => {
								markerRefs.current[item.id] = m;
							}}
						>
							<Popup><div className="text-sm font-bold">{item.name}</div></Popup>
						</CircleMarker>)
					}
				</FeatureGroup>
			</MapContainer>
		</div>
	);
}

export default MapView;
