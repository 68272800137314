import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Container, PageHeading } from "./Components";

import _ from 'underscore';

import config from "../config";

function PersonList(props) {
	const [personsData, setPersonsData] = useState(null);
	const [listData, setListData] = useState(null);
	const [searchFormValue, setSearchFormValue] = useState('');

	const location = useLocation();

	const apiRoot = config.apiRoot+'travel/persons?limit=500'

	const fetchData = () => {
		fetch(apiRoot)
			.then(res => res.json())
			.then(json => {
				setPersonsData(json.results);
				setListData(json.results);
			});
	}

	useEffect(() => {
		if (!personsData) {
			fetchData();
		}
	});

	useEffect(() => {
		if (searchFormValue.length > 2) {
			setListData(_.filter(personsData, (item) => {
				return item.name.toLowerCase().indexOf(searchFormValue.toLowerCase()) > -1
			}));
		}
		else {
			setListData(personsData);
		}
	}, [searchFormValue]);

	return <Container>

		<div className="mb-10">
			<input type="text" 
				placeholder="Search" 
				value={searchFormValue}
				onChange={(event) => setSearchFormValue(event.target.value)}
				className="rounded-md shadow-md p-2 border-gray-300 focus:outline-none focus:border-gray-400 border bg-white/100" />
		</div>

		<table>
			<thead>
				<th>Name</th>
				<th>Year of birth</th>
				<th>Year of death</th>
			</thead>
			<tbody>
				{
					listData && listData.map((item, index) => <tr>
						<td><Link to={location.pathname+'/'+item.id}>
							{item.name}
						</Link></td>
						<td>{item.birth}</td>
						<td>{item.death}</td>
					</tr>)
				}
			</tbody>
		</table>
	</Container>;
}

export default PersonList;