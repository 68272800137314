import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Container, LinkIcon } from "./Components";

import config from "../config";

function BibliographyList(props) {
	const [data, setData] = useState(null);

	let { placeId } = useParams()

	const apiRoot = config.apiRoot+'travel/bibliographies'

	useEffect(() => {
		if (!data) {
			fetch(apiRoot+'?limit=500')
				.then(res => res.json())
				.then(json => setData(json.results));
		}
	});

	return <Container>
		{
			data && <table>
				<thead>
					<th>Title</th>
					<th>Author</th>
					<th>Publication year</th>
					<th>Source url</th>
				</thead>
				<tbody>
					{
						data.map((item, index) => <tr key={index}>
							<td><Link to={'/travelling/writing/'+item.id}>{item.title}</Link></td>
							<td>{
								item.author && <Link to={'/travelling/women/'+item.author.id}>{item.author.name}</Link>
							}</td>
							<td>{item.publication_date}</td>
							<td>{
								item.source_url && <a href={item.source_url} className="text-nowrap no-underline">View <LinkIcon /></a>
							}</td>
						</tr>)
					}
				</tbody>
			</table>
		}

	</Container>;
}

export default BibliographyList;