import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Container, PageHeading, Heading } from "./Components";

import config from "../config";

function PlaceView(props) {
	const [placeData, setPlaceData] = useState(null);
	const [placeTravelsData, setPlaceTravelsData] = useState([]);

	let { placeId } = useParams()

	const apiRoot = config.apiRoot+'travel/'

	useEffect(() => {
		fetch(apiRoot+'places/'+placeId)
			.then(res => res.json())
			.then(json => setPlaceData(json));

		fetch(apiRoot+'travels/?place='+placeId)
			.then(res => res.json())
			.then(json => setPlaceTravelsData(json.results));
	}, [placeId]);

	return <Container>
		{
			placeData && <>
				<PageHeading>{placeData.name}</PageHeading>
			</>
		}
		{
			placeTravelsData && <>
				<Heading>Ferðabækur</Heading>
				<table>
					<thead>
						<th>Author</th>
						<th>Year</th>
						<th>Publication</th>
					</thead>
					<tbody>
						{
							placeTravelsData.map((item, index) => <tr key={index}>
								<td><Link to={'/travelling/travels/'+item.id}>{item.person.name}</Link></td>
								<td>{item.travel_dates && item.travel_dates.length > 0 && item.travel_dates[0]}</td>
								<td>{item.travel_publication && item.travel_publication.title}</td>
							</tr>)
						}
					</tbody>
				</table>
			</>
		}
	</Container>;
}

export default PlaceView;