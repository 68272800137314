import { useEffect, useState } from "react";
import {Container} from "./Components";
import _ from "underscore";

function TabContainer(props) {
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => setSelectedTab(0), [props.children]);

	return <div>
		<Container>
			<div className="flex">
				{
					_.compact(props.children).map((child, index) => child.props && child.props.tabTitle ? <div key={index} 
						className={'p-4 border-b-2 cursor-pointer'+(selectedTab == index ? ' font-bold border-orange-500' : '')}
						onClick={() => setSelectedTab(index)}
					>{child.props.tabTitle}</div> : null)
				}
			</div>
		</Container>
		<div>
			{
				_.compact(props.children).map((child, index) => <div key={index} 
					className={selectedTab == index ? '' : 'h-0 overflow-hidden'}
				>
					{child}
				</div>)
			}
		</div>
	</div>
}

export default TabContainer;