import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import _ from 'underscore';

import { Container, PageHeading, Heading } from "./Components";

import PersonGraphView from './PersonGraphView';

import config from "../config";

function PersonView(props) {
	const [personData, setPersonData] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	const map = useRef();
	const markerRefs = useRef({});

	let { personId } = useParams();

	const apiRoot = config.apiRoot+'travel/';

	useEffect(() => {
		fetch(apiRoot+'persons/'+personId)
			.then(res => res.json())
			.then(json => {
				setPersonData(json);

				if (json.travels && json.travels.length > 0 && location.pathname.indexOf('/persons/') > -1) {
					navigate('/travelling/women/'+personId, {
						replace: true
					});
				}
			});
	}, [personId]);

	console.log(personData)

	return <div>
		{
			personData && <>
				<Container>
					<PageHeading subHeading={
						(personData.birth || '')+
						(personData.birth && personData.death ? '-' : '')+
						(personData.death || '')}>{personData.name}</PageHeading>

					{
						personData.travels && personData.travels.length > 0 &&
						<div className="font-lora text-xl mb-4">Travelled to Iceland in {
								_.uniq(_.compact(_.flatten(personData.travels.map(travel => travel.travel_dates.map(date => date))))).join(', ')
							}
						</div>
					}

					<div className="flex flex-row-reverse">
						{
							personData.image && <div className="w-1/3 ml-8">
								<img className="rounded-md shadow-md" src={personData.image.replace('130.208.108.18:8000', 'ginnungagap.arnastofnun.is')} />
							</div>
						}

						<div className="w-full">
							{
								personData.biography && <p>{personData.biography}</p>
							}

							{
								personData.travels && personData.travels.length > 0 && <>
									<Heading>Icelandic travel narratives and selected other writing</Heading>
									<table>
										<thead>
											<th>Author</th>
											<th>Year</th>
											<th>Publication</th>
										</thead>
										<tbody>
											{
												personData.travels.map((item, index) => <tr key={index}>
													<td><Link to={'/travelling/travels/'+item.id}>{item.person.name}</Link></td>
													<td>{item.travel_dates.length > 0 && item.travel_dates[0]}</td>
													<td>
														{
															item.travel_publication && <Link to={'/travelling/writing/'+item.travel_publication.id}>{item.travel_publication.title}</Link>
														}
													</td>
												</tr>)
											}
										</tbody>
									</table>
								</>
							}

							{
								personData.person_sources && personData.person_sources.length > 0 && <>
									<Heading>Sources</Heading>
									<table>
										<thead>
											<th>Title</th>
											<th>Type</th>
										</thead>
										<tbody>
											{
												personData.person_sources.map((item, index) => <tr key={index}>
													<td><a href={item.source}>{item.title}</a></td>
													<td>{item.source_type.name}</td>
												</tr>)
											}
										</tbody>
									</table>
								</>
							}
						</div>
					</div>

					{
						personData.person_travels && personData.person_travels.length > 0 && <>
							<Heading>Appears in travel narratives</Heading>
							<table>
								<thead>
									<th>Ferðabók</th>
									<th>Reference mode</th>
									<th>Hlutverk</th>
								</thead>
								<tbody>
									{
										personData.person_travels.map((item, index) => <tr key={index}>
											<td><Link to={'/travelling/travels/'+item.travel.id}>{item.travel.title}</Link></td>
											<td>{item.reference_mode}</td>
											<td>{item.person_role}</td>
										</tr>)
									}
								</tbody>
							</table>
						</>
					}

					<Heading>Social Network</Heading>

				</Container>

				<Container wide={false} className="relative z-10">

					<PersonGraphView personId={personId} />

				</Container>

			</>
		}
	</div>
}

export default PersonView;