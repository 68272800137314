import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import _ from 'underscore';

import { Container, PageHeading } from "./Components";
import TabContainer from "./TabContainer";

import config from "../config";

function TravelView(props) {
	const [travelData, setTravelData] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [travelYears, setTravelYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const navigate = useNavigate();

	const map = useRef();
	const markerRefs = useRef({});

	let { travelId } = useParams();

	const apiRoot = config.apiRoot+'travel/';

	const fitBounds = (places) => {
		try {
			if (map.current) {
				map.current.fitBounds(places.map((item) => [item.lat, item.lng]), {
					padding: [40, 40]
				});
			}
		}
		catch (e) {}
	}

	useEffect(() => {
		fetch(apiRoot+'travels/'+travelId)
			.then(res => res.json())
			.then(json => {
				setTravelData(json);
				
				let places = [];
				let years = [];

				if (json.from_place) {
					places.push({
						reference_mode: 'start',
						place: json.from_place
					});
				}

				json.travel_events.forEach(item => {
					if (item.place) {
						places.push(item);
					}

					if (item.year) {
						years.push(item.year);
					}
				});

				setTravelYears(_.compact(_.uniq(years)));
				setMapData(places);

				setTimeout(() => {					
					fitBounds(places);
				}, 100);
			});
	}, [travelId]);

	useEffect(() => {
		let places = [];

		if (travelData) {
			if (travelData.from_place) {
				places.push({
					reference_mode: 'start',
					place: travelData.from_place
				});
			}

			travelData.travel_events.forEach(item => {
				if ((item.place && item.year == selectedYear) || (item.place && !selectedYear) || item.reference_mode == 'start') {
					places.push(item);
				}
			});

			setMapData(places);

			fitBounds(places);
	}
	}, [selectedYear]);

	const iconResize = 0.6;

	const defaultIcon = new L.icon({
		iconAnchor: [12*iconResize, 41*iconResize],
		iconRetinaUrl: '/kvennaspor-frontend/img/marker-icon-2x.png',
		iconSize: [25*iconResize, 41*iconResize],
		iconUrl: '/kvennaspor-frontend/img/marker-icon.png',
		popupAnchor: [1*iconResize, -34*iconResize],
		shadowSize: [41*iconResize, 41*iconResize],
		shadowUrl: '/kvennaspor-frontend/img/marker-shadow.png',
		tooltipAnchor: [16*iconResize, -28*iconResize]
	});

	return <div>
		{
			travelData && <>
				<Container>
					<PageHeading subHeading={travelData.travel_dates.join(', ')}>{travelData.person.name}</PageHeading>

					{
						travelData.person && <div className="mb-8 flex gap-4">
							
							<div>
								{
									travelData.travel_publication && <p className="mb-8">Publication: <Link className="button p-3" to={'/travelling/writing/'+travelData.travel_publication.id}>{travelData.travel_publication.title}</Link></p>
								}

								{
									travelData.from_place && <div className="mb-8"v>From place: <Link to={'/travelling/places/'+travelData.from_place.id}>{travelData.from_place.name}</Link></div>
								}
								<p>{travelData.person.biography}</p>
							</div>

							<Link to={'/travelling/women/'+travelData.person.id} 
								className="group no-underline text-center basis-1"
							>
								{
									<div className="rounded-md shadow-md bg-white/50 bg-center bg-contain bg-no-repeat transition-all duration-500 group-hover:scale-105 w-[200px] h-[240px]" style={travelData.person.image ? {
										backgroundImage: 'url('+travelData.person.image.replace('130.208.108.18:8000', 'ginnungagap.arnastofnun.is')+')'
									} : null} />
								}
			
								<h4 className="group-hover:underline decoration-slate-400 mb-2">
									{travelData.person.name}
								</h4>
								{
									/*
								<div className="text-gray-500 text-sm">Travelled to Iceland in {
										_.uniq(_.compact(_.flatten(travelData.person.travel_author.map(travel => travel.travel_dates.map(date => date))))).join(', ')
									}
								</div>
									*/
								}
							</Link>

						</div>
					}

				</Container>

				<TabContainer>
					{
						travelData.travel_events && travelData.travel_events.length > 0 && mapData && mapData.length > 0 && <div tabTitle={'Events'} tabName={'events'}>
							{/*
							<Container>
								{
									travelYears.length > 1 && <div className="flex">
										<a className={'button mr-2'+(!selectedYear ? ' selected' : '')} onClick={() => setSelectedYear(null)}>Show all</a>
										{
											travelYears.map((year, index) => <a key={index} 
												className={'button mr-2'+(year == selectedYear ? ' selected' : '')}
												onClick={() => setSelectedYear(year)}>{year}</a>)
										}
									</div>
								}
							</Container>

							<Container wide={true} className="sticky top-4 z-10">
								<MapContainer ref={map} center={[64.963051, -19.020836]} minZoom={4} maxZoom={12} zoom={4} scrollWheelZoom={false}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://nafnid.is/gis/island-tiles/{z}/{x}/{y}.png"
									/>
									<TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
										attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
									/>
									<FeatureGroup>
										{
											mapData.map((item, index) => <Marker key={index}
												icon={defaultIcon}
												title={item.place.name}
												position={[item.place.lat, item.place.lng]}
												eventHandlers={{
													//click: (event) => navigate('/places/'+item.id)
												}}
												ref={(m) => {  // <--- add marker refs to ref object here
													markerRefs.current[item.place.id] = m;
												}}
											>
												<Popup>
													<div className="text-sm font-bold">{item.place.name}</div>
													<div><Link to={'/travelling/places/'+item.place.id}>View</Link></div>
												</Popup>
											</Marker>)
										}
									</FeatureGroup>
									{
										selectedYear && <Polyline pathOptions={{
											color: '#ffffff',
											weight: 2
										}} positions={_.filter(mapData, (item) => item.reference_mode == 'visited' || item.reference_mode == 'start').map((item) => [item.place.lat, item.place.lng])} />
									}
								</MapContainer>
							</Container>
							*/}
								
							<Container>
								<table className="w-full mt-8">
									<thead>
										<tr>
											<th>Year</th>
											<th>Place</th>
											<th>Reference mode</th>
											<th>Saga</th>
										</tr>
									</thead>
									{
										(selectedYear ? _.filter(travelData.travel_events, (item) => item.year == selectedYear) : travelData.travel_events).map((item, index) => <tr key={index} 
											onMouseOver={() => {
												if (item.place) {
													let marker = markerRefs.current[item.place.id];

													if (marker) {
														marker.openPopup();
													}
												}
											}}
										>
											<td>{item.year}</td>
											<td>
												{
													item.place && <Link to={'/travelling/places/'+item.place.id}>{item.place.name}</Link>
												}
											</td>
											<td>{(item.reference_mode ? item.reference_mode : '')+(item.reference_mode && item.duration_of_stay ? '/' : '')+(item.duration_of_stay ? item.duration_of_stay : '')}</td>
											<td>
												{
													item.saga_mention && <Link to={'/travelling/saga/'+item.saga_mention.id}>{item.saga_mention.title}</Link>
												}
											</td>
										</tr>)
									}
								</table>									
							</Container>
						</div>
					}

					{
						travelData.travel_persons.length > 0 && <Container tabTitle={'Persons'} tabName={'persons'}>
							<table className="w-full mt-8">
								<thead>
									<tr>
										<th>Person</th>
										<th>Person role</th>
										<th>Reference mode</th>
									</tr>
								</thead>
								{
									travelData.travel_persons.map((item, index) => <tr key={index}>
										<td><Link to={'/travelling/women/'+item.person.id}>{item.person.name}</Link></td>
										<td>{item.person_role}</td>
										<td>{item.reference_mode}</td>
									</tr>)
								}
							</table>
						</Container>					
					}

				</TabContainer>
			</>
		}
	</div>
}

export default TravelView;