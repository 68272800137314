import * as d3 from 'd3';
import _ from 'underscore';

export default {
	linkColors: {
		'1': d3.schemeCategory10[0],
		'2': d3.schemeCategory10[1],
		'3': d3.schemeCategory10[2],
		'4': d3.schemeCategory10[3],
		'5': d3.schemeCategory10[8],
		'6': d3.schemeCategory10[4],
		'7': d3.schemeCategory10[6],
		'8': d3.schemeCategory10[7],
		'9': d3.schemeCategory10[8]
	},

	groupGraphData: function(graphData) {
		let ret = {
			nodes: [],
			connections: []
		}

		ret.nodes = _.uniq(graphData, (item) => item.person_id).map((item) => {
			if (item.person_id) {
				return {
					id: item.person_id,
					name: item.person_name,
					traveller: item.traveller
				}
			}
		});

		ret.nodes = _.compact(ret.nodes);

		let travellers = _.where(graphData, {traveller: true});

		travellers.forEach(traveller => {
			_.where(graphData, {travel_id: traveller.travel_id}).forEach((item) => {
				if (!_.findWhere(ret.connections, {source: traveller.person_id, target: item.person_id, type: item.reference_mode, reference_mode_id: item.reference_mode_id}) && traveller.person_id != item.person_id) {
					if (traveller.person_id && item.person_id) {
						ret.connections.push({source: traveller.person_id, target: item.person_id, type: item.reference_mode, reference_mode_id: item.reference_mode_id})
					}
				}
			});
		});

		return ret;
	}
}
