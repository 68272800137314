import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Container, PageHeading } from "./Components";

import _ from 'underscore';

import config from "../config";

function WomenList(props) {
	const [personsData, setPersonsData] = useState(null);
	const [listData, setListData] = useState(null);
	const [searchFormValue, setSearchFormValue] = useState('');
	const [selectedYear, setSelectedYear] = useState(null);
	const [yearData, setYearData] = useState(null);

	const location = useLocation();

	const apiRoot = config.apiRoot+'travel/persons?limit=500'

	const countYears = (data) => {
		let yearsCount = {}

		data.forEach(item => {
			if (item.travel_dates) {
				item.travel_dates.split(/,|\//).forEach((year, index) => {
					let yearKey = year.split('-')[0];

					if (yearKey.length == 4) {
						yearsCount[yearKey] = yearsCount[yearKey] || {count: 0}

						yearsCount[yearKey].count += 1;
					}
				})
			}
		});

		return _.sortBy(Object.keys(yearsCount).map((year) => {
			return {
				year: year,
				count: yearsCount[year].count
			}
		}), 'year');
	}

	const fetchData = () => {
		fetch(apiRoot+'&female_authors=true')
			.then(res => res.json())
			.then(json => {
				setPersonsData(json.results);
				setListData(json.results);
				setYearData(countYears(json.results));
			});
		/*
		fetch(config.apiRoot+'travel/year_count')
			.then(res => res.json())
			.then(json => setYearData(json.results));
		*/
	}

	useEffect(() => {
		if (!personsData) {
			fetchData();
		}
	});

	const minCircleRadius = 10;
	const maxCircleRadius = 35;

	const yearSizeValue = (value) => {
		return yearData ? (
				value - _.min(_.pluck(yearData, 'count')) * (maxCircleRadius - minCircleRadius) / (_.max(_.pluck(yearData, 'count')) - _.min(_.pluck(yearData, 'count'))) + minCircleRadius
			) : 0;
	}

	const getTimelinePos = (year) => {
		let minYear = _.min(_.pluck(yearData, 'year'));
		let maxYear = _.max(_.pluck(yearData, 'year'));
		
		return year < minYear ? 0 : ((year-minYear)/(maxYear-minYear))*100;
	}

	useEffect(() => {
		if (searchFormValue.length > 2) {
			setListData(_.filter(personsData, (item) => {
				return item.name.toLowerCase().indexOf(searchFormValue.toLowerCase()) > -1
			}));
		}
		else {
			setListData(personsData);
		}
	}, [searchFormValue]);

	useEffect(() => {
		if (selectedYear) {
			setListData(_.filter(personsData, (item) => {
				return item.travel_dates && item.travel_dates.indexOf(selectedYear) > -1
			}));
		}
		else {
			setListData(personsData);
		}
	}, [selectedYear]);

	return <Container>

		<div className="mb-10">
			<input type="text" 
				placeholder="Search" 
				value={searchFormValue}
				onChange={(event) => {
					setSearchFormValue(event.target.value);
					setSelectedYear(null);
				}}
				className="rounded-md shadow-md p-2 border-gray-300 focus:outline-none focus:border-gray-400 border bg-white/100" />


			<div className="mt-4 mb-6">
				<h4>Timeline</h4>
				{
					yearData && <div className="min-h-[55px] flex items-center justify-evenly relative">
						<div className="absolute border-t border-gray-300 left-0 right-0"></div>
						{
							yearData.map((item, index) => <div key={index} 
								className="group cursor-pointer absolute" 
								style={{left: getTimelinePos(item.year)+'%'}}
								onClick={() => {
									setSelectedYear(item.year);
									setSearchFormValue('');
								}}
							>
								<div className={'border rounded border-gray-400 bg-white '+(selectedYear == item.year ? ' outline outline-2 outline-orange-400' : '')} style={{width: 7, height: yearSizeValue(item.count)}} />
								<div className="pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity absolute -top-5 text-xs left-[50%] z-20 -translate-x-[50%]">{item.year}</div>
							</div>)
						}
					</div>
				}
			</div>

		</div>

		<div className="flex flex-wrap gap-y-14 gap-x-8">
			{
				listData && listData.map((item, index) => <Link key={index} to={location.pathname+'/'+item.id} 
					className="group no-underline text-center basis-1"
				>
					{
						<div className="rounded-md shadow-md bg-white/50 bg-center bg-contain bg-no-repeat transition-all duration-500 group-hover:scale-105 w-[160px] h-[200px]" style={item.image ? {
							backgroundImage: 'url('+item.image.replace('130.208.108.18:8000', 'ginnungagap.arnastofnun.is')+')'
						} : null} />
					}

					<h4 className="group-hover:underline decoration-slate-400 mb-2">
						{item.name}
					</h4>
					{
						item.travel_dates &&
						<div className="text-gray-500 text-sm">Travelled to Iceland in {
								item.travel_dates.split(',').join(', ')
							}
						</div>
					}
				</Link>)
			}
		</div>
	</Container>;
}

export default WomenList;