import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Container, PageHeading } from "./Components";

import _ from 'underscore';

import config from "../config";

function PersonList(props) {
	const [listData, setListData] = useState(null);

	const location = useLocation();

	const apiRoot = config.apiRoot+'articles?limit=500';

	let { newsId } = useParams();

	const fetchData = () => {
		fetch(apiRoot)
			.then(res => res.json())
			.then(json => {
				setListData(json.results);
			});
	}

	useEffect(() => {
		if (!listData) {
			fetchData();
		}
	});

	const formatDate = (d) => {
		return (new Date(d)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
	};

	return newsId && listData ? <div className="w-full">
		{
			(() => {
				let newsItem = _.findWhere(listData, {id: parseInt(newsId)})

				if (newsItem) {
					return <>
						<h3>{newsItem.title}</h3>
						<p className="text-gray-500 mb-2">{formatDate(newsItem.date)}</p>
						<div className="text-container" dangerouslySetInnerHTML={{__html: newsItem.text}} />
						{
							newsItem.image_path && <img className="rounded mb-2" src={newsItem.image_path.replace('http://130.208.108.18:8000/', 'https://kvennaspor.is/')} />
						}
					</>
				}
			})()
		}
	</div> : <div className="grid grid-cols-2 xl:grid-cols-4 gap-y-14 gap-x-8 w-full">
		{
			listData && listData.map((item, index) => <Link key={index} to={location.pathname+'/'+item.id} 
				className="group no-underline text-center rounded-md shadow-md bg-white/50 hover:scale-105 p-5"
			>
				{
					item.image_path && <div className="w-full h-40 rounded bg-cover bg-center" style={{
						backgroundImage: 'url('+item.image_path.replace('http://130.208.108.18:8000/', 'https://kvennaspor.is/')+')'
					}} />
				}
				<h4 className="group-hover:underline decoration-slate-400 mb-2">
					{item.title}
				</h4>
				{
					<div className="text-gray-500 text-sm">{formatDate(item.date)}</div>
				}
			</Link>)
		}
	</div>
}

export default PersonList;