import React, { Component } from 'react';
import * as d3 from 'd3';
import _ from 'underscore';

import utils from './../utils/graphUtils';
import config from './../config';

class GraphView extends Component {
	constructor(props) {
		super(props);

		this.maxRenderedNodes = 2500;
		this.maxRenderedLinks = 2500;

		this.zoomValue = 1;

		this.initialZoom = 3.5;

		this.normalRadius = 6;
		this.highlightedRadius = 8;

		let uniqueId = props.uniqueId || (new Date()).getTime();

		this.nodeColors = {
			OsFletta: d3.schemePaired[0],
			RelFl: d3.schemePaired[1],
		};

		this.state = {
			hoverInfo: null,
			hoverInfoMax: false,
			maximized: false,
			drawing: false,
			uniqueId: uniqueId
		}
	}

	componentDidMount() {
		if (this.svgRoot) {
			return;
		}

		if (!this.props.disableZoom) {
			this.zoom = d3.zoom().on('zoom', function () {
				this.zoomValue = d3.event.transform.k;

				this.svg.attr('transform', d3.event.transform);

				this.link
					.style('stroke-width', function(l) {
						return this.getStrokeWidth(l);
					}.bind(this));

				this.node.select('circle').attr('r', function(n) {
					return this.normalRadius/this.zoomValue;
				}.bind(this));

				this.label.style('font-size', function(d) {
						return (14/this.zoomValue)+'px';
					}.bind(this))
					.attr('x', function(d) {
						return d.x + (8/this.zoomValue);
					}.bind(this))
					.attr('y', function(d) {
						return d.y + (3/this.zoomValue);
					}.bind(this));


				this.linkLabels.style('font-size', function(d) {
						return (12/this.zoomValue)+'px';
					}.bind(this))
					.style('stroke-width', function(d) {
						return 0.5/this.zoomValue;
					}.bind(this));

			}.bind(this));
		}
		else {
			this.drag = d3.drag().on('start', function(d) {
				if (!d3.event.active) this.simulation.alphaTarget(0.3).restart();
				//d.fx = d.x;
				//d.fy = d.y;
			}.bind(this))
			.on('drag', function(d) {
				this.svg.attr('transform', 'translate('+(d3.event.x-d3.event.subject.x)+', '+(d3.event.y-d3.event.subject.y)+')');
				//d.fx = d3.event.x;
				//d.fy = d3.event.y;
			}.bind(this))
			.on('end', function(d) {
				if (!d3.event.active) this.simulation.alphaTarget(0);
				//d.fx = null;
				//d.fy = null;
			}.bind(this));
		}

		if (this.props.disableZoom) {
			this.svgRoot = d3.select(document.getElementById('graphContainer'+this.state.uniqueId))
				.call(this.drag)
				.append('svg')
				.attr('width', '100%')
				.attr('height', '100%');
		}
		else {
			this.svgRoot = d3.select(document.getElementById('graphContainer'+this.state.uniqueId))
				.call(this.zoom)
				.append('svg')
				.attr('width', '100%')
				.attr('height', '100%');

		}

		this.svg = this.svgRoot.append('g');

		this.svgRoot.append('defs').append('marker')
			.attr('id', 'arrowhead'+this.state.uniqueId)
						.attr('viewBox', '-0 -5 10 10')
						.attr('refX', 20)
						.attr('refY', 0)
						.attr('orient', 'auto')
						.attr('markerWidth', 7)
						.attr('markerHeight', 7)
						.attr('xoverflow', 'visible')
			.append('svg:path')
			.attr('d', 'M 0,-5 L 10 ,0 L 0,5')
			.attr('fill', '#999')
			.style('stroke','none');

		//this.zoom.scaleTo(this.svg.transition(), this.initialZoom);

		if (this.props.graphData && this.props.graphData.nodes) {
			this.createGraph();
			this.setState({
				waitForRenderPermission: false,
				drawing: true
			}, () => {
				this.updateGraph();
			});
		}
	}

	riskOfSlowness() {
		var isFirefox = typeof InstallTrigger !== 'undefined';
		var isIE = /*@cc_on!@*/false || !!document.documentMode;
		var isEdge = !isIE && !!window.StyleMedia;

		return isFirefox || isIE || isEdge;
	}

	encodeHtmlText(str) {
		return str.split('>').join('&gt;').split('<').join('&lt;');
	}

	createGraph(update) {

		if (!this.props.graphData) {
			return;
		}

		// Graf hreinsað til að tryggja að ný gögn skili sér
		d3.selectAll('#graphContainer'+this.state.uniqueId+' svg g > *').remove();
		let width = document.getElementById('graphContainer'+this.state.uniqueId).offsetWidth;
		let height = document.getElementById('graphContainer'+this.state.uniqueId).offsetHeight;

		this.simulation = d3.forceSimulation()
			.force('link', d3.forceLink().distance(this.props.distance || 150).id(function(d) {
				return d.id;
			}))
			.force('collide', d3.forceCollide().radius(this.props.collideRadius || 50))
			.force('charge', d3.forceManyBody().strength(-350).distanceMin(10))
			.force('center', d3.forceCenter(width / 2, height / 2))
			.stop();

		this.link = this.svg.append('g')
			.on('scroll', function() {
			})
			.attr('class', 'links')
			.selectAll('line');

		this.linkPathsContainer = this.svg.append('g')
			.attr('class', 'link-paths');

		this.node = this.svg.append('g')
			.attr('class', 'nodes')
			.selectAll('g');

		this.label = this.svg.append('g')
			.attr('class', 'labels')
			.selectAll('text');

		this.linkPaths = this.linkPathsContainer.selectAll('path');

		this.linkLabels = this.linkPathsContainer.selectAll('text');

	}

	updateGraph() {
		if (!this.props.graphData || !this.props.graphData.connections || !this.props.graphData.nodes) {
			return;
		}
/*
		console.log('nodes: '+this.props.graphData.nodes.length)
		console.log(this.maxRenderedNodes)
		console.log('connections: '+this.props.graphData.connections.length)
		console.log(this.maxRenderedLinks)
		console.log('this.props.graphData.nodes.length > this.maxRenderedNodes: '+(this.props.graphData.nodes.length > this.maxRenderedNodes));
		console.log('this.props.graphData.connections.length > this.maxRenderedLinks: '+(this.props.graphData.connections.length > this.maxRenderedLinks));
		console.log('slow: '+(this.props.graphData.nodes.length > this.maxRenderedNodes || this.props.graphData.connections.length > this.maxRenderedLinks));
*/
		if ((this.props.graphData.nodes.length > this.maxRenderedNodes || this.props.graphData.connections.length > this.maxRenderedLinks) && !this.state.waitForRenderPermission) {
			this.setState({
				waitForRenderPermission: true
			});

			return;
		}

		this.link = this.link.data(this.props.graphData.connections)
			.enter().append(this.props.straightLines ? 'line' : 'path')
			.attr('stroke', function(d) {
				return utils.linkColors[d.person_role_id] || '#ccc';
			}.bind(this))
			.attr('fill', 'none')
			.attr('stroke-width', function(d) {
				return this.getStrokeWidth(d);
			}.bind(this))
			.on('mouseover', function(d) {
			}.bind(this))
			.on('mouseout', function(d) {
			}.bind(this));

		if (!this.linkedByIndex) {
			this.linkedByIndex = {};
			this.props.graphData.connections.forEach(function(d) {
				this.linkedByIndex[(d.source || d.source) + ',' + (d.target || d.target)] = d.type;
			}.bind(this));
		}
		let linkedByIndex = this.linkedByIndex;

		let isConnected = function(a, b) {
			return linkedByIndex[a.id + ',' + b.id] || linkedByIndex[b.id + ',' + a.id] || a.id == b.id;
		};

		let graphView = this;

		this.node = this.node.data(this.props.graphData.nodes)
			.enter().append('g')
			.call(d3.drag()
			.on('start', function(d) {
				if (!d3.event.active) this.simulation.alphaTarget(0.3).restart();
					d.fx = d.x;
					d.fy = d.y;
			}.bind(this))
			.on('drag', function(d) {
				d.fx = d3.event.x;
				d.fy = d3.event.y;
			})
			.on('end', function(d) {
				if (!d3.event.active) this.simulation.alphaTarget(0);
					d.fx = null;
					d.fy = null;
			}.bind(this)))
			.on('mouseover', function(d) {
				let hoverInfo = '';

				let hoverX = d3.event.clientX;
				let hoverY = d3.event.clientY;

				if (d.label == 'OsFletta') {
					hoverInfo = (d.properties.rnum && d.properties.rnum > 0 ? '('+d.properties.rnum+') ' : '')+'<strong>'+d.properties.fletta+'</strong><br/>'+
							'<p></p>';

					if (this.state.hoverInfoId != d.id) {

						let relNodes = _.filter(this.props.graphData.nodes, (c) => isConnected(c, d) && c.id != d.id);

						window.hoverTimeout = setTimeout(() => {
							fetch(config.apiRoot+'/api/faersla/'+d.id)
								.then(res => res.json())
								.then(json => {
									let entryText = json.faersla_text;

									relNodes.forEach((item, i) => {
										entryText = entryText.split('<i>'+item.properties.fletta+'</i>').join('<i><span class="highlight">'+item.properties.fletta+'</span></i>')
									});


									let hoverInfo = (d.properties.rnum && d.properties.rnum > 0 ? '('+d.properties.rnum+') ' : '')+'<strong>'+d.properties.fletta+'</strong><br/>'+
										'<p>'+entryText+'</p>';

									if (window.hoverTimeout) {
										this.setState({
											hoverInfo: hoverInfo,
											hoverInfoMax: false,
											hoverInfoId: json.id,
											hoverX: hoverX,
											hoverY: hoverY
										});

										window.hoverMaxTimeout = setTimeout(() => {
											this.setState({
												hoverInfoMax: true
											});
										}, 600)
									}
								});
						}, 100);
					}
				}
				if (d.label == 'RelFl') {
					hoverInfo = '';

					this.setState({
						hoverInfo: hoverInfo,
						hoverInfoMax: false,
						hoverX: hoverX,
						hoverY: hoverY
					});
				}

				this.link
					.style('stroke-width', function(l) {
						if (d === l.source || d === l.target) {
							return this.getStrokeWidth(l);
						}
						else {
							return this.getStrokeWidth(l);
						}
					}.bind(this))
					.style('stroke-opacity', function(l) {
						if (d === l.source || d === l.target)
							return 1;
						else
							return 0.3;
					}.bind(this))
					.style('stroke', function(l) {
						if (d === l.source || d === l.target) {
							return utils.linkColors[l.type] || '#ccc';
						}
						else {
							return '#f2f2f2';
						}
					}.bind(this))
					.attr('marker-end', function(l) {
						if (d === l.source || d === l.target) {
							return !this.props.disableArrows ? 'url(#arrowhead'+this.state.uniqueId+')' : null;
						}
						else {
							return null;
						}
					}.bind(this));

				this.node.select('circle').attr('r', function(n) {
					if (isConnected(d, n)) {
						return this.highlightedRadius/this.zoomValue;
					}
					else {
						return (this.normalRadius/3)/this.zoomValue;
					}
				}.bind(this));

				this.label.style('visibility', function(n) {
					if (isConnected(d, n)) {
						return 'visible';
					}
					else {
						return 'hidden';
					}
				}.bind(this));

				this.linkLabels.style('visibility', function(l) {
					if (d === l.source || d === l.target) {
						return 'visible';
					}
					else {
						return 'hidden';
					}
				}.bind(this));
			}.bind(this))
			.on('mouseout', function(d) {
				if (window.hoverTimeout || window.hoverMaxTimeout) {
					console.log('clearTimeout')
					clearTimeout(window.hoverTimeout);
					clearTimeout(window.hoverMaxTimeout);
					window.hoverTimeout = false;
					window.hoverMaxTimeout = false;
				}

				this.setState({
					hoverInfo: null,
					hoverInfoMax: false,
					hoverInfoId: null
				});

				this.link
					.style('stroke-width', function(l) {
						return this.getStrokeWidth(l);
					}.bind(this))
					.style('stroke-opacity', 1)
					.style('stroke', function(l) {
						return utils.linkColors[l.type] || '#ccc';
					}.bind(this))
					.attr('marker-end', function(l) {
						return !this.props.disableArrows ? 'url(#arrowhead'+this.state.uniqueId+')' : null;
					}.bind(this));

				this.node.select('circle').attr('r', function(n) {
					return this.normalRadius/this.zoomValue;
				}.bind(this));

				this.label.style('visibility', 'visible');

				this.linkLabels
					.style('visibility', 'visible');

			}.bind(this))
			.on('click', function(d) {
				if (this.props.onNodeClick) {
					this.props.onNodeClick(d);
				}
			}.bind(this));

		this.node.append('circle')
			.attr('r', function(d) {
				return this.normalRadius/this.zoomValue;
			}.bind(this))
			.attr('stroke', '#ff9500')
			.attr('stroke-width', function(d) {
				return this.props.highlighId && this.props.highlighId.indexOf(d.id) > -1 ? (3/this.zoomValue)+'px' : '0';
			}.bind(this))
			.attr('class', function(d) {
				return d.id ? 'clickable' : '';
			})
			.attr('fill', function(d) {
				return this.props.mainId && this.props.mainId == d.id ? '#cc0000' : (this.props.highlighId && this.props.highlighId.indexOf(d.id) > -1 ? '#fff' : this.nodeColors[d.label] || '#a9a9a9')

			}.bind(this));

		this.label = this.label.data(this.props.graphData.nodes)
			.enter().append('text')
			.text(function(d) {
				let f = _.find(this.props.graphData.connections, function(l) {
					return l.source == d.id || l.target == d.id;
				});

				return d.name;
			}.bind(this))
			.style('visibility', function(d) {
					return 'visible';
			}.bind(this))
			.style('font-size', function(d) {
				return (14/this.zoomValue)+'px';
			}.bind(this))
			.style('font-weight', function(d) {
				return this.props.highlighId && this.props.highlighId.indexOf(d.id) > -1 ? 'bold' : 'normal'
			}.bind(this))
			.attr('x', function(d) {
				return d.x + (8/this.zoomValue);
			}.bind(this))
			.attr('y', function(d) {
				return d.y + (3/this.zoomValue);
			}.bind(this));

		this.linkPaths = this.linkPaths.data(this.props.graphData.connections)
			.enter()
			.append('path');

		this.linkPaths
			.attr('d', function(d) {return 'M '+d.source.x+' '+d.source.y+' L '+ d.target.x +' '+d.target.y})
			.attr('class', 'edgepath')
			.attr('fill-opacity', 0)
			.attr('stroke-opacity', 0)
			.attr('fill', 'blue')
			.attr('stroke', 'red')
			.attr('id', function(d, i) {
				return 'edgepath'+i;
			})
			.style('pointer-events', 'none');

		this.linkLabels = this.linkLabels.data(this.props.graphData.connections)
			.enter()
			.append('text');

		this.linkLabels.style('font-size', function(d) {
				return (10/this.zoomValue)+'px';
			}.bind(this))
			.style('text-shadow', 'rgb(255, 255, 255) 0px 0px 2px, rgb(255, 255, 255) 0px 0px 2px, rgb(255, 255, 255) 0px 0px 2px, rgb(255, 255, 255) 0px 0px 2px, rgb(255, 255, 255) 0px 0px 2px')
			.on('mouseover', function(d) {
				if (graphView.props.showForeignLanguages && d.properties && d.properties.props && d.properties.props.def) {
					let hoverInfo = d.properties.type;

					let hoverX = d3.event.clientX;
					let hoverY = d3.event.clientY;

					this.setState({
						hoverInfo: d.properties.props.def,
						hoverX: d.x,
						hoverY: d.y
					});
				}
			}.bind(this))
			.on('mouseout', function(d) {
				this.setState({
					hoverInfo: null
				});
			}.bind(this));

		this.linkLabels.append('textPath')
			.attr('xlink:href',function(d, i) {
				return '#edgepath'+i
			})
			.attr('text-anchor', 'middle')
			.attr('dominant-baseline', 'middle')
			.attr('startOffset', '50%')
			.text(function(d, i) {
				return d.type;
			})

		this.simulation
			.nodes(this.props.graphData.nodes)
		this.simulation.force('link')
			.links(this.props.graphData.connections);
	    this.simulation.tick(500);

		let updateTick = function() {

			if (graphView.props.straightLines) {
				graphView.link
					.attr('x1', function(d) { return d.source.x; })
					.attr('y1', function(d) { return d.source.y; })
					.attr('x2', function(d) { return d.target.x; })
					.attr('y2', function(d) { return d.target.y; })
			}
			else {
				graphView.link
					.attr('d', function(d) {
						var dx = d.target.x - d.source.x,
							dy = d.target.y - d.source.y,
							dr = Math.sqrt(dx * dx + dy * dy)+(Math.random(10)+50);

						return "M" +
							d.source.x + "," +
							d.source.y + "A" +
							dr + "," + dr + " 0 0,1 " +
							d.target.x + "," +
							d.target.y;
					});
			}

			graphView.node
				.attr('transform', function(d) {
					return 'translate('+d.x+','+d.y+')';
				});

			graphView.label
				.attr('x', function(d) {
					return d.x + (8/graphView.zoomValue);
				}.bind(this))
				.attr('y', function(d) {
					return d.y + (3/graphView.zoomValue);
				}.bind(this));

			graphView.linkPaths
				.attr('d', function(d) {
					return 'M '+d.source.x+' '+d.source.y+' L '+ d.target.x +' '+d.target.y;
				});

			graphView.linkLabels.attr('transform', function(d, i) {
				if (d.target.x < d.source.x) {
					let bbox = this.getBBox();

					let rx = bbox.x+bbox.width/2;
					let ry = bbox.y+bbox.height/2;

					return 'rotate(180 '+rx+' '+ry+')';
				}
				else {
					return 'rotate(0)';
				}
			});
		}

		updateTick();

		if (!this.riskOfSlowness()) {
			this.simulation.on('tick', updateTick.bind(this));
		}

		this.updateLegend();

		this.setState({
			drawing: false
		});
	}

	hasConnection(a, type) {
		let found = _.find(this.props.graphData.connections, function(c) {
			return (c.source.properties.id == a.properties.id || c.target.properties.id == a.properties.id) && c.properties.type == type;
		});

		if (found) {
			return true;
		}
		else {
			return false;
		}
	}

	highlightConnections(connectionType) {
		this.link
			.style('stroke-opacity', function(l) {
				if (l.type == connectionType) {
					return 1;
				}
				else {
					return 0.05;
				}
			}.bind(this));

		this.label.style('visibility', function(n) {
			if (this.hasConnection(n, connectionType)) {
				return 'visible';
			}
			else {
				return 'hidden';
			}
		}.bind(this));

		this.node.select('circle').attr('r', function(n) {
			if (this.hasConnection(n, connectionType)) {
				return this.normalRadius/this.zoomValue;
			}
			else {
				return (this.normalRadius/3)/this.zoomValue;
			}
		}.bind(this));
	}

	showAllConnections() {
		this.link.style('stroke-opacity', 1);

		this.label.style('visibility', 'visible');

		this.node.select('circle').attr('r', function(n) {
			return this.normalRadius/this.zoomValue;
		}.bind(this));
	}

	updateLegend() {
		if (!this.props.graphData || !this.props.graphData.connections) {
			return;
		}

		let legendTypes = _.uniq(_.map(this.props.graphData.connections, function(item) {
			return item.type;
		}));

		this.setState({
			graphLinkTypes: legendTypes
		});
	}

	getStrokeWidth(d, modifier, add) {
		let strokeWidth = 1;

		if (modifier) {
			strokeWidth = strokeWidth*modifier;
		}

		if (add) {
			strokeWidth = strokeWidth+add;
		}

		return strokeWidth/this.zoomValue;
	}

	getStrokeColor(d) {

	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.graphData && this.props.graphData != prevProps.graphData
		) {
			this.linkedByIndex = null;

			this.setState({
				waitForRenderPermission: false,
				drawing: true,
				hoverInfo: null,
				hoverInfoId: null
			}, () => {
				setTimeout(() => {
					this.createGraph();
					this.updateGraph();
				}, 200);
			});

			this.setState({
				maximized: this.props.mainId == prevProps.mainId ? this.state.maximized : false
			});
		}
	}

	render() {
		let wrapperStyle = {};

		if (this.props.graphHeight) {
			wrapperStyle.height = this.props.graphHeight+'px';
		}

		return (
			<div className={'graph-wrapper'+(this.state.maximized ? ' maximized' : '')}
				style={wrapperStyle}
			>
				<div className="graph-container"
					id={'graphContainer'+this.state.uniqueId}
					ref="graphContainer"
					onMouseOut={() => {
						this.setState({
 		   					hoverInfo: null,
 		   					hoverInfoId: null
 		   			})
				   }} />
				{
					this.state.hoverInfo &&
					<div className={'hover-info no-truncate font-junicode'+(this.state.hoverInfoMax ? ' max-height' : '')}
						dangerouslySetInnerHTML={{__html: this.state.hoverInfo}}
						style={{
							top: this.state.hoverY,
							left: this.state.hoverX
						}} />
				}
				{
					this.props.enableFullView &&
					<div className="full-view-button">
						<button onClick={function() {
							this.setState({
								maximized: !this.state.maximized
							}, function() {
								if (this.props.graphData) {
									this.createGraph();

									this.setState({
										drawing: true
									}, () => {
										setTimeout(() => {
											this.updateGraph();
										}, 200);
									});
								}
							}.bind(this));
						}.bind(this)}><span>{this.state.maximized ? 'Minnka' : 'Stækka'}</span></button>
					</div>
				}

				<div className="controls">
					{
						this.props.children
					}
				</div>

				{
					((this.state.drawing || this.props.waiting) && !this.state.waitForRenderPermission) &&
					<div className="no-results-overlay"><span>Teikna myndrit...</span></div>
				}
				{
					this.props.graphData && this.props.graphData.nodes && this.props.graphData.nodes.length == 0 && !this.props.waiting &&
					<div className="no-results-overlay"><span>Engin gögn fundust fyrir myndrit</span></div>
				}

				{
					this.state.waitForRenderPermission &&
					<div className="no-results-overlay" style={this.state.waitForRenderPermission ? { pointerEvents: 'all' } : null}>
						<span>Niðurstöður inniheldur {this.props.graphData.nodes.length} flettur og {this.props.graphData.connections.length} tengingar, hætt er við að myndrit verði hægvirkt og ólesanlegt.
						{/*<br/><br/><button onClick={() => {
							this.setState({
								waitForRenderPermission: false
							});
						}}>Halda áfram</button>*/}</span>
					</div>
				}
			</div>
		);
	}
}

export default GraphView;
